import {
	computed,
	ref,
} from 'vue';

import {
	ChatbotConversationMessage,
	ChatbotRoles,
	ChatbotTexts,
} from '@zyro-inc/site-modules/types';

// TODO implement when backend is ready
export const useSiteAiAssistant = () => {
	const conversationHistory = ref<ChatbotConversationMessage[]>([]);
	const isChatbotResponding = ref(false);
	const isChatbotRestarting = ref(false);

	const isChatbotInitialized = computed(() => !!conversationHistory.value.length);

	const initialChatbotMessage = computed<ChatbotConversationMessage[]>(() => ([
		{
			role: ChatbotRoles.assistant,
			content: 'Welcome to our store! 👋\n\nHow can I assist you today?',
		},
	]));

	const chatbotTexts: ChatbotTexts = {
		main: {
			button: 'Ask Assistant',
			questionInputPlaceholder: 'Ask assistant anything',
			tooltipReset: 'Reset',
			tooltipClose: 'Close',
		},
		modalRestart: {
			title: 'Clear chat',
			description: 'After clearing history you won’t be able to access previous chats.',
			cancelButton: 'Cancel',
			confirmButton: 'Confirm',
		},
	};

	const chatbotInitialize = async () => {
		if (isChatbotInitialized.value) {
			// return
		}

		conversationHistory.value = initialChatbotMessage.value;
		// TODO set conversationHistory
	};

	const chatbotRespond = async (content: string) => {
		if (isChatbotResponding.value) {
			return;
		}

		conversationHistory.value = [
			...conversationHistory.value,
			{
				role: ChatbotRoles.user,
				content,
			},
			{
				role: ChatbotRoles.assistant,
			},
		];

		isChatbotResponding.value = true;

		// TODO update conversationHistory

		isChatbotResponding.value = false;
	};

	const chatbotRestart = async () => {
		isChatbotRestarting.value = true;

		// TODO implement restart logic

		conversationHistory.value = initialChatbotMessage.value;
		isChatbotRestarting.value = false;
	};

	return {
		chatbotInitialize,
		chatbotRespond,
		chatbotRestart,
		conversationHistory,
		isChatbotResponding,
		isChatbotRestarting,
		chatbotTexts,
	};
};
