<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import {
	ref,
	computed,
	onMounted,
	onBeforeUnmount,
} from 'vue';

import ChatbotBase from '@zyro-inc/site-modules/components/chatbot/ChatbotBase.vue';
import type {
	ChatbotTexts,
	ChatbotConversationMessage,
} from '@zyro-inc/site-modules/types';
import { MEDIA_MOBILE_SCREEN_BREAKPOINT } from '@zyro-inc/site-modules/constants';

type Props = {
  conversationHistory: ChatbotConversationMessage[];
  isResponding: boolean;
  isRestarting: boolean;
  texts: ChatbotTexts;
  topPosition?: number;
  mobileBreakpoint?: number;
  isMobileEnabled?: boolean;
  isRestartVisible?: boolean;
  isClosable?: boolean;
};

const emit = defineEmits<{
  initialize: [];
  respond: [message: string];
  restart: [];
  'function-click': [
    { name: string; arguments: Record<string, string> | undefined },
  ];
}>();

const props = withDefaults(defineProps<Props>(), {
	topPosition: 100,
	mobileBreakpoint: MEDIA_MOBILE_SCREEN_BREAKPOINT,
	isMobileEnabled: false,
	isRestartVisible: true,
	isClosable: true,
});

const isVisible = ref(false);
const isMobile = ref(false);
const isChatOpen = ref(false);
const topPosition = computed(() => `${props.topPosition}px`);

const toggleChat = async () => {
	if (!isChatOpen.value) {
		emit('initialize');
	}

	isChatOpen.value = !isChatOpen.value;
};

const chatbotBase = ref(null);

const handleScreenResize = () => {
	isMobile.value = window.innerWidth < props.mobileBreakpoint;
	isVisible.value = !isMobile.value || props.isMobileEnabled;
};

onMounted(() => {
	if (isChatOpen.value) {
		emit('initialize');
	}

	window.addEventListener('resize', handleScreenResize);

	handleScreenResize();

	// Handle for screen size not yet available when opening in new tab
	setTimeout(() => handleScreenResize(), 500);
});
onBeforeUnmount(() => {
	window.removeEventListener('resize', handleScreenResize);
});
onClickOutside(chatbotBase, () => {
	if (isChatOpen.value && isMobile.value) {
		isChatOpen.value = false;
	}
});
</script>

<template>
	<div
		v-show="isVisible"
		class="chatbot-wrapper"
		:class="{
			'chatbot-wrapper--hidden': !isChatOpen,
			'chatbot-wrapper--mobile': isMobile,
		}"
	>
		<Transition
			name="slide-top-bottom"
			appear
		>
			<ChatbotBase
				v-if="isChatOpen"
				ref="chatbotBase"
				:texts="texts"
				:is-responding="props.isResponding"
				:is-restarting="props.isRestarting"
				:conversation-history="props.conversationHistory"
				:is-restart-visible="props.isRestartVisible"
				:is-closable="props.isClosable"
				@toggle-chat="toggleChat"
				@respond="emit('respond', $event)"
				@restart="emit('restart')"
			>
				<template #custom-content>
					<slot name="custom-content" />
				</template>
			</ChatbotBase>
		</Transition>
		<div
			class="chatbot-wrapper__resource-triggers"
			:class="{ 'chatbot-wrapper__resource-triggers--hidden': isChatOpen && isMobile, }"
		>
			<button
				data-qa="ai-chatbot-button"
				class="chatbot-wrapper__ai-chatbot-button"
				@click="toggleChat"
			>
				<span>{{ texts.main.button }}</span>
			</button>
		</div>
		<div
			v-show="isChatOpen && isMobile"
			class="chatbot-wrapper__backdrop"
		/>
	</div>
</template>

<style scoped lang="scss">
.chatbot-wrapper {
  font-family: Arial, sans-serif;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: calc(100% - v-bind(topPosition));
  width: 360px;
  right: 8px;
  bottom: 0;
  z-index: 999;
  justify-content: flex-end;
  overflow: hidden;
  filter: drop-shadow(0 0 12px rgba(#1d1e20, 0.16));

  &--hidden {
    pointer-events: none;
  }

  &--mobile {
    width: 100%;
    right: 0;
    overflow: visible;
  }

  &__resource-triggers {
    display: flex;
    margin-left: auto;
    z-index: 999;
    width: fit-content;
    padding: 4px;
    border-radius: 50%;
    margin-bottom: 8px;
    pointer-events: auto;

    &--hidden {
      display: none;
    }
  }

  &__ai-chatbot-button {
    font-family: Arial, sans-serif;
    background-color: var(--color-light);
    color: var(--color-dark);
    cursor: pointer;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    &:hover {
      background-color: var(--color-gray-light);
    }
  }

  &__backdrop {
    position: absolute;
    left: 0;
    background-color: rgba(#1d1e20, 0.35);
    width: 100vw;
    height: 100vh;
    z-index: 998;
    top: calc(-1 * v-bind(topPosition));
  }
}

.slide-top-bottom {
  &-enter-active,
  &-leave-active {
    transition: transform 0.15s cubic-bezier(0.2, 0.8, 0.2, 1.1),
      opacity 0.2s cubic-bezier(0.2, 0.8, 0.2, 1.1);
    transform-origin: bottom right;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: scale(0.8);
  }

  &-enter-to,
  &-leave-from {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
