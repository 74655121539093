<script lang="ts" setup>
import { watch } from 'vue';

import ChatbotModal from '@zyro-inc/site-modules/components/chatbot/modals/ChatbotModal.vue';
import type { ChatbotTextsModalRestart } from '@zyro-inc/site-modules/types';
import ChatbotLoader from '@zyro-inc/site-modules/components/chatbot/ChatbotLoader.vue';

type Props = {
  isRestarting: boolean;
  texts: ChatbotTextsModalRestart;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'on-close': [];
  'on-restart': [];
}>();

watch(
	() => props.isRestarting,
	(value) => {
		if (value) return;

		emit('on-close');
	},
);
</script>

<template>
	<ChatbotModal @close="emit('on-close')">
		<div class="chatbot-restart-modal">
			<h4 class="text-bold-2">
				{{ texts.title }}
			</h4>
			<p class="chatbot-restart-modal__description text-body-2">
				{{ texts.description }}
			</p>

			<div class="chatbot-restart-modal__buttons">
				<button
					data-qa="chatbot-restart-modal-cancel-btn"
					class="chatbot-restart-modal__close-button chatbot-restart-modal__button"
					button-type="outlined"
					:disabled="isRestarting"
					theme="plain"
					@click="emit('on-close')"
				>
					{{ texts.cancelButton }}
				</button>

				<button
					data-qa="chatbot-restart-modal-clear-chat-btn"
					class="chatbot-restart-modal__restart-button chatbot-restart-modal__button"
					:is-loading="isRestarting"
					theme="plain"
					@click="emit('on-restart')"
				>
					<ChatbotLoader v-if="isRestarting" />
					<span v-else>
						{{ texts.confirmButton }}
					</span>
				</button>
			</div>
		</div>
	</ChatbotModal>
</template>

<style lang="scss" scoped>
.chatbot-restart-modal {
  padding: 16px;

  &__description {
    margin-top: 4px;
    color: var(--color-gray);
  }

  &__buttons {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  &__button {
	min-width: 103px;
	font-family: Arial, sans-serif;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    border-radius: var(--z-border-radius, 8px);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: border-color, background-color, color;
    font-size: 14px;
    min-height: 40px;
    padding: 7px 23px;
    line-height: 24px;
    font-weight: 700;
  }

  &__close-button {
	color: var(--color-gray-dark);
    border: 1px solid var(--color-gray-border);

	&:hover {
      background-color: var(--color-gray-light);
      border: 1px solid var(--color-gray-border);
	}

	&:disabled {
      background-color: var(--color-gray-light);
      color: var(--color-gray);
      cursor: default;
	}
  }

  &__restart-button {
    color: var(--color-light);;
    background-color: var(--color-gray-dark);
    border: 1px solid var(--color-gray-dark);

	&:hover {
      background-color: var(--color-gray);
      border: 1px solid var(--color-gray);
	}

	:deep() {
      .chatbot-loader__element {
		border-color: var(--color-gray-light) transparent transparent;

		&:nth-child(4) {
          border-color: var(--color-gray-light);
        }
      }
    }
  }
}
</style>
