<script setup lang="ts">

import { computed } from 'vue';

import Chatbot from '@zyro-inc/site-modules/components/chatbot/Chatbot.vue';
import { useSiteAiAssistant } from '@zyro-inc/site-modules/use/useSiteAiAssistant';

interface Props {
	aiSalesAssistant?: {
		isEnabled: boolean;
	}
}

const props = defineProps<Props>();

const {
	chatbotInitialize,
	chatbotRespond,
	conversationHistory,
	isChatbotResponding,
	chatbotRestart,
	isChatbotRestarting,
	chatbotTexts,
} = useSiteAiAssistant();

const isAiSalesAssistantEnabled = computed(() => props.aiSalesAssistant?.isEnabled);

</script>

<template>
	<Chatbot
		v-if="isAiSalesAssistantEnabled"
		:top-position="100"
		:conversation-history="conversationHistory"
		:is-responding="isChatbotResponding"
		:is-restarting="isChatbotRestarting"
		:texts="chatbotTexts"
		is-mobile-enabled
		@initialize="chatbotInitialize"
		@respond="chatbotRespond"
		@restart="chatbotRestart"
	/>
</template>
